export const environment = {
  production: false,
  rootRoute: "https://apigateway2.enovate.app/",
  frontendRoute: "https://apigateway2.enovate.app/frontend-accesscontrol-development/api",
  rootStream: "https://apigateway2.enovate.app/streams-api",
  apiTokens: {
    default: "74df8f1f910fcb4be73473cba2547c05a277ac6b0025579371d5bcf0bf6f4557",
    ropmsethresholds: "5f673640caa3fe7e92c99896630fb769712f54e03f5c92c1fa876eec664cff0e",
    streamingdata: "cf5e0234b5f12d10577ebc5ed860a07f5a4a10a3b17a8fba6c4f3881e7188e60",
    startdepth: "5d077e2bfea1b3b1705682cddc3aee8dca6f1fa3dc9b71ee4d3a7a13dac73ceb",
    ropmsegraph: "5d077e2bfea1b3b1705682cddc3aee8dca6f1fa3dc9b71ee4d3a7a13dac73ceb",
    rigactivity: "74a4571dbe93bbb47189be1d045732bea3edb7949e521187ec8ddcf7ebe34a97",
    streamingscroll: "cf5e0234b5f12d10577ebc5ed860a07f5a4a10a3b17a8fba6c4f3881e7188e60",
    colors: "4653cf50081bcc72a60dc67b50e00fadd6392c58572c4aaee5cc3d20690c0710",
    schedulesgeneration: "b370d8624a7e366130c60a57093fc26ee49794c0d9439b74edda30dc3e31a52e",
    wells: "547d06b2dfd842b3062d201acfb348db15ba4e955b2011848fe164d8d28d423e",
    scenarios: "3da260ebc158b8a625893d2f1e3a088ac9948370c329f4b9a709a996d43cb243",
    parameters: "19f3cb46e454277b6eb6b16f3eee77f663ea95078dfb6816f5bb1ad1e236ec57"
  },
  keyEncrypt: ",%0<x98r?\\u8s_AjIjt2gZLFc71E0zUh",
  apiRoutes: {
    getWells: "https://apigateway2.enovate.app/streams-api/selectoperatorwells",
    getProyects: "https://apigateway2.enovate.app/frontend-accesscontrol-development/api/Proyects",
    getOperator: "https://apigateway2.enovate.app/frontend-accesscontrol-development/api/Username"
  }
};

