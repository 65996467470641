import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlarmType, projectData } from 'src/app/pages/main/types/project-data.type';
import { ConvertService } from '../convert/convert.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private abortController = new AbortController();
  private rootRoute = "https://apigateway2.enovate.app/";
  private frontendRoute = "https://apigateway2.enovate.app/frontend-accesscontrol-test/api";

  cancelRequests() {
    this.abortController.abort(); // Abortar todas las solicitudes
    this.abortController = new AbortController(); // Reiniciar el AbortController para futuras solicitudes
  }

  private defaultHeader = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Token I2Hrh4NFD2xBE6bb7SS1syz7g7OEA5HEjEjglP2wV'
  };

  private routeHeaders = {
    'ropmsethresholds': 'Token rJ6bDIa5lz9YjarwEJs48hT7PRdeDm5vIVySKj7sJ',
    'streamingdata': 'Token VM6apuZliSTr57uwppi8GqYECLXUhPXitgxPI4v7r',
    'startdepth': 'Token bZE2GBrDNz9y4l00z3eRdW4tSeNcTgB22Od4jwgqe',
    'ropmsegraph': 'Token bZE2GBrDNz9y4l00z3eRdW4tSeNcTgB22Od4jwgqe',
    'rigactivity': 'Token OQ1hv3LWCjUo0k6gAY45JP9rs61RXpsZJzJzr3awi',
    'streamingscroll': 'Token VM6apuZliSTr57uwppi8GqYECLXUhPXitgxPI4v7r',
    'colors': 'Token UzQnCBQCdkjSNZwTELrUi82o7Z48pmKURyO8THRIx',
    'schedulesgeneration': 'Token YnnTHhwa50Lu4xz3LjgN0RvtoTUsgX7l92cokcWbk'
  };

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Token VM6apuZliSTr57uwppi8GqYECLXUhPXitgxPI4v7r'
  });

  userData = { username: "", scenario: "", well: "", project: "", client: "" };
  
  istest = false;

  testprod: any = "test";

  constructor(public http: HttpClient, private convert: ConvertService) {}

  getUser(){
    return new Promise((resolve) => {
      this.userData.username = localStorage.getItem("usernameDrillOpt")?.toLowerCase() || "";
      this.userData.scenario = localStorage.getItem("scenarioSelectedDrillOpt")?.toLowerCase() || "";
      this.userData.well = localStorage.getItem("wellSelectedDrillOpt")?.toLowerCase() || "";
      this.userData.project = localStorage.getItem("projectSelectedDrillOpt")?.toLowerCase() || "";
      this.userData.client = localStorage.getItem("clientSelectedDrillOpt")?.toLowerCase() || "";
      resolve(this.userData);
    });
  }

  setUser(username: any){
    return new Promise((resolve) => {
      username = (username.substring(0, username.indexOf("@")).replace("-","_").replace(".","_").replace("@","_")).toLowerCase();
      localStorage.setItem("usernameDrillOpt", username);
      this.userData.username = username;
      resolve(this.userData);
    });
  }

  getApiData(wellname: any, username: any, route: any, rows: any, onlyusername: boolean, lastrow: any, operator: any, wellactual: any, project: any){
    return new Promise((resolve, reject) => {
      let headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token I2Hrh4NFD2xBE6bb7SS1syz7g7OEA5HEjEjglP2wV'
      }
      let requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + route + "/"+username+"/"+wellname;
      if(onlyusername){
        apiroute = this.rootRoute + route + "/"+username;
      }
      if(route.includes("ropmsethresholds")){
        apiroute = this.rootRoute + route + "/"+operator+"/"+project+"/"+wellactual+"/"+username+"/"+wellname;
        headerDict = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token rJ6bDIa5lz9YjarwEJs48hT7PRdeDm5vIVySKj7sJ'
        }
        requestOptions = {                                                                                                                                                                                 
          headers: new HttpHeaders(headerDict), 
          signal: this.abortController.signal
        };
      }
      if(route.includes("streamingdata")){
        apiroute = this.rootRoute + route + "/"+operator+"/"+project+"/"+wellactual+"/"+username+"/"+wellname;
        headerDict = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token VM6apuZliSTr57uwppi8GqYECLXUhPXitgxPI4v7r'
        }
        requestOptions = {                                                                                                                                                                                 
          headers: new HttpHeaders(headerDict), 
          signal: this.abortController.signal
        };
      }
      if(route.includes("startdepth") || route.includes("ropmsegraph")){
        apiroute = this.rootRoute + route + "/"+operator+"/"+project+"/"+wellactual+"/"+username+"/"+wellname;
        headerDict = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token bZE2GBrDNz9y4l00z3eRdW4tSeNcTgB22Od4jwgqe'
        }
        requestOptions = {                                                                                                                                                                                 
          headers: new HttpHeaders(headerDict), 
          signal: this.abortController.signal
        };
      }
      if(route.includes('rigactivity')){
        apiroute = this.rootRoute + route + "/"+operator+"/"+project+"/"+wellactual+"/"+username+"/"+wellname;
        headerDict = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token OQ1hv3LWCjUo0k6gAY45JP9rs61RXpsZJzJzr3awi'
        }
        requestOptions = {                                                                                                                                                                                 
          headers: new HttpHeaders(headerDict), 
          signal: this.abortController.signal
        };
      }
      if(route.includes("streamingscroll")){
        apiroute = this.rootRoute + route + "/"+operator+"/"+project+"/"+wellactual+"/"+username+"/"+wellname;
        headerDict = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token VM6apuZliSTr57uwppi8GqYECLXUhPXitgxPI4v7r'
        }
        requestOptions = {                                                                                                                                                                                 
          headers: new HttpHeaders(headerDict), 
          signal: this.abortController.signal
        };
      }
      if(route.includes("colors")){
        apiroute = this.rootRoute + route + "/"+operator+"/"+project+"/"+wellactual+"/"+username+"/"+wellname;
        headerDict = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token UzQnCBQCdkjSNZwTELrUi82o7Z48pmKURyO8THRIx'
        }
        requestOptions = {                                                                                                                                                                                 
          headers: new HttpHeaders(headerDict), 
          signal: this.abortController.signal
        };
      }
      if(rows && rows >= 0){
        apiroute += "/"+rows.toString();
      }
      if(lastrow && lastrow >= 0){
        apiroute += "/"+lastrow.toString();
      }
      if(route.includes("schedulesgeneration")){
        apiroute = `${this.rootRoute}${route}/${username}/${wellname}`

        const newHeaderDict = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token YnnTHhwa50Lu4xz3LjgN0RvtoTUsgX7l92cokcWbk'
        }

        const newRequestOptions = {                                                                                                                                                                                 
          headers: new HttpHeaders(newHeaderDict), 
        };

        this.http.post(apiroute, '', newRequestOptions)
        .subscribe((response: any) => {
          let res = JSON.stringify(response);
          let responses: any = JSON.parse(res);
          if(responses.hasOwnProperty("data")){
            resolve(this.convert.convertUnits(responses.data, route));
          } else {
            resolve(this.convert.convertUnits(responses, route));
          }
        }, error => {
          reject(error);
        });
        return;
      }
      if(this.istest){
        apiroute = "assets/json/" + route.replace(".","_").replace("/","_") + ".json";
      }
      this.http.get(apiroute, requestOptions)
      .subscribe((response: any) => {
        let res = JSON.stringify(response);
        let responses: any = JSON.parse(res);
        if(responses.hasOwnProperty("data")){
          resolve(this.convert.convertUnits(responses.data, route));
        } else {
          resolve(this.convert.convertUnits(responses, route));
        }
      }, error => {
        reject(error);
      });
    });
  }


  importApiDataFile(fileToUpload: File, route: any, wellname: any, username: any): any {
    return new Promise((resolve, reject) => {
      let apiroute = this.rootRoute + route +"/"+username+"/"+wellname;
      let formData: FormData = new FormData();
      formData.append('upload', fileToUpload);
      const headerDict = {
        'Authorization': 'Token I2Hrh4NFD2xBE6bb7SS1syz7g7OEA5HEjEjglP2wV'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
        signal: this.abortController.signal
      };
      // const requestOptions = { headers };
      this.http.post(apiroute, formData, requestOptions)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  postApiData(route: any, wellname: any, username: any, data: any): any {
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token I2Hrh4NFD2xBE6bb7SS1syz7g7OEA5HEjEjglP2wV'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + route + "/"+username+"/"+wellname;
      this.http.post(apiroute, data, requestOptions)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
  
  putApiData(wellname: any, username: any, route: any, data: any){
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token d226b1976fd098e46697ae42868a454109e151ac'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + route + "/"+wellname+"/"+username;
      this.http.put(apiroute, data, requestOptions)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  // getIridianRoute = "https://apigateway2.enovate-upstream.com/drillbismf-"+this.testprod+"/api";
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjU3MjczMTc1MjkiLCJpYXQiOjE3MTM5MTkzNjEsImV4cCI6MTcxNTEyODk2MX0.aTmlaIScahLZKRqc9tyGXjzAj9k44yvOv57DHscC36M
  // getIridianRoute = "https://apigateway2.enovate.app/frontend-accesscontrol-test/api";

  // getOperator(uid: any){
  //   return new Promise((resolve, reject) => {
  //     const headers = new HttpHeaders();
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     const requestOptions = { headers };
  //     this.http.get(this.getIridianRoute + "/get-usuario?uid="+uid, requestOptions)
  //       .subscribe((response: any) => {
  //         resolve(response);
  //       }, error => {
  //         reject(error);
  //       });
  //   });
  // }

  getOperator(userData: any){
    return new Promise((resolve, reject) => {
      const dataLogin: any = JSON.parse(localStorage.getItem("dataLogin") || "");
      let token: any = dataLogin.token;
      const headers = {
        "x-access-token": `${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }

      const requestOptions = { headers, signal: this.abortController.signal };
      this.http.get(this.frontendRoute + "/Username"+"/"+dataLogin.table+"/"+userData, requestOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  getScenarios(username: any){
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token d226b1976fd098e46697ae42868a454109e151ac'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + "drilloptim-scenarios-"+this.testprod+"/scenarioslist/" + username;
      this.http.get(apiroute, requestOptions)
      .subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  getScenario(username: any, scenario: any){
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token d226b1976fd098e46697ae42868a454109e151ac'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + "drilloptim-scenarios-"+this.testprod+"/scenario/" + username + "/" + scenario;
      this.http.get(apiroute, requestOptions)
      .subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  createUpdateScenario(username: any, scenario: any, data: any, create: boolean = true){
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token d226b1976fd098e46697ae42868a454109e151ac'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + "drilloptim-scenarios-"+this.testprod+"/scenario/" + username + "/" + scenario;
      if(create){
        this.http.post(apiroute, data, requestOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
      } else {
        this.http.put(apiroute, data, requestOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
      }
    });
  }

  deleteScenario(username: any, scenario: any){
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token d226b1976fd098e46697ae42868a454109e151ac'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + "drilloptim-scenarios-"+this.testprod+"/scenario/" + username + "/" + scenario;
      this.http.delete(apiroute, requestOptions)
      .subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  rootStream = "https://apigateway2.enovate.app/streams-api";
  getWellsRoute: any = this.rootStream + "/selectoperatorwells";

  getWellsFromApi(operator: any){
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token UJzBeQ0QyIZTW2hH1by6Ir575Prfv1thvG39wQfXj'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
        signal: this.abortController.signal
      };
      this.http.get(this.getWellsRoute + "/" + operator, requestOptions)
      .subscribe(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  async getProjectData(){
    return new Promise<projectData[]>((resolve, reject) => {
      const dataLogin: any = JSON.parse(localStorage.getItem("dataLogin") || "");
      let token: any = dataLogin.token;
      const headers = {
        "x-access-token": `${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
      const requestOptions = { headers, signal: this.abortController.signal };
      this.http.get(this.frontendRoute + "/Proyects", requestOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  getCompleteRealtimeData(route: any, user: any){
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token VM6apuZliSTr57uwppi8GqYECLXUhPXitgxPI4v7r'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + route + "/"+ user.client + "/" + user.project + "/" + user.well + "/" + user.username + "/" + user.scenario;
      this.http.get(apiroute, requestOptions)
      .subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  } 

  getAlarms(route: string): Observable<any> {
    return new Observable(observer => {
      this.getUser().then((userData: any) => {
        const endpoint = `${this.rootRoute}${route}/alarm/get_alarms/${userData.client}/${userData.project}/${userData.well}/${userData.username}/${userData.scenario}`;
        this.http.get(endpoint, { headers: this.headers }).subscribe(
          (data) => {
            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      });
    });
  }

  postAlarm(body: { alarms: AlarmType[] }, route: string): Observable<any> {
    return new Observable(observer => {
      this.getUser().then((userData: any) => {
        const endpoint = `${this.rootRoute}${route}/alarm/create_alarm/${userData.client}/${userData.project}/${userData.well}/${userData.username}/${userData.scenario}`;
        this.http.post(endpoint, body, { headers: this.headers }).subscribe(
          (data) => {
            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      });
    });
  }

  putAlarm(id: number, body: AlarmType, route: string): Observable<any> {
    return new Observable(observer => {
      this.getUser().then((userData: any) => {
        const endpoint = `${this.rootRoute}${route}/alarm/update_alarm/${userData.client}/${userData.project}/${userData.well}/${userData.username}/${userData.scenario}/${id}`;
        this.http.put(endpoint, body, { headers: this.headers }).subscribe(
          (data) => {
            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      });
    });
  }

  deleteAlarm(id: number, route: string): Observable<void> {
    return new Observable(observer => {
      this.getUser().then((userData: any) => {
        const endpoint = `${this.rootRoute}${route}/alarm/delete_alarm/${userData.client}/${userData.project}/${userData.well}/${userData.username}/${userData.scenario}/${id}`;
        this.http.delete(endpoint, { headers: this.headers }).subscribe(
          () => {
            observer.next();
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      });
    });
  }
    
}
