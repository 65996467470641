import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { TooltipComponent } from '../pages/main/shared/tooltip/tooltip.component';

@NgModule({
  declarations: [TooltipComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatTooltipModule,
    RecaptchaFormsModule,
    RecaptchaModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatTooltipModule,
    RecaptchaFormsModule,
    RecaptchaModule, 
    TooltipComponent
  ]
})
export class SharedModule { }